/* eslint-disable camelcase */
import { admin, admin2 } from '@/api/instance'

const category = (shopId) => {
  return `/${shopId}/salesCategory`
}

const product = (shopId) => {
  return `/${shopId}/salesProduct`
}

const unit = (shopId) => {
  return `/${shopId}/salesUnit`
}

const unitReport = (shopId) => {
  return `/${shopId}/salesUnitReport`
}

const unitReportPermission = (shopId) => {
  return `/${shopId}/salesUnitReportPermission`
}

const discount = (shopId) => {
  return `/${shopId}/salesDiscount`
}

const record = (shopId) => {
  return `/${shopId}/salesRecord`
}

const group = (shopId) => {
  return `/${shopId}/salesGroup`
}

// !! 創建

//= > 創建銷售紀錄
export const CreateSalesRecord = async ({
  shopId,
  memberId,
  identity,
  phone,
  items,
  discounts,
  payments,
  totalItemsPrice,
  totalDiscountPrice,
  totalPaymentPrice,
  totalAddCashback,
  sellAt,
  originDevice,
}) => {
  const res = await admin({
    method: 'post',
    url: record(shopId),
    data: {
      memberId,
      identity,
      phone,
      items,
      discounts,
      payments,
      totalItemsPrice,
      totalDiscountPrice,
      totalPaymentPrice,
      totalAddCashback,
      sellAt,
      originDevice: 'dashboard',
    },
  })

  return res.data
}

//= > 創建銷售類別
export const CreateSalesCategory = async ({
  shopId,
  name,
  order,
  salesProducts,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'post',
    url: category(shopId),
    data: {
      name,
      order,
      salesProducts,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 創建銷售產品
export const CreateSalesProduct = async ({
  shopId,
  name,
  needUnit,
  defaultPrice,
  order,
  salesCategories,
  useClassTicket,
  classTicketId,
  revenueType,
  revenueValue,
  revenueSplitCost,
  revenueSplitRate,
  useCoin,
  coinAmount,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'post',
    url: product(shopId),
    data: {
      name,
      needUnit,
      defaultPrice,
      order,
      salesCategories,
      useClassTicket,
      classTicketId,
      revenueType,
      revenueValue,
      revenueSplitCost,
      revenueSplitRate,
      useCoin,
      coinAmount,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 創建銷售人員
export const CreateSalesUnit = async ({
  shopId,
  name,
  profit,
  order,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'post',
    url: unit(shopId),
    data: {
      name,
      profit,
      order,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 創建銷售優惠
export const CreateSalesDiscount = async ({
  shopId,
  name,
  type,
  cashAmount,
  percentAmount,
  mustSpendSetting,
  order,
}) => {
  return await admin2({
    method: 'post',
    url: discount(shopId),
    data: {
      name,
      type,
      cashAmount,
      percentAmount,
      mustSpendSetting,
      order,
    },
  })
}

//= > 創建銷售報表 google sheet
export const CreateSalesReportSheet = async ({
  shopId,
  start,
  end,
}) => {
  const res = await admin({
    method: 'post',
    url: `/${shopId}/salesReport/sheet`,
    data: {
      start,
      end,
    },
  })

  return res.data
}

// !! 取得
//= > 取得銷售紀錄
export const GetSalesRecord = async ({
  shopId,
  start,
  limit,
  phone,
  sellAt_gte,
  sellAt_lte,
  orderNo,
  branchName,
  MemberId,
}) => {
  const res = await admin({
    method: 'get',
    url: record(shopId),
    params: {
      start,
      limit,
      phone,
      sellAt_gte,
      sellAt_lte,
      orderNo,
      branchName,
      MemberId,
    },
  })

  return res.data
}

//= > 取得銷售報表
export const GetSalesReport = async ({
  shopId,
  start,
  end,
}) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesReport/show`,
    params: {
      start,
      end,
    },
  })

  return res.data
}

//= > 取得銷售報表 google sheet 進度及連結
export const GetSalesReportSheet = async ({
  id,
  shopId,
}) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesReport/sheet/${id}`,
    params: {
    },
  })

  return res.data
}

//= > 取得銷售類別
export const GetSalesCategory = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: category(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得銷售產品
export const GetSalesProduct = async ({
  shopId,
  start,
  limit,
  name,
  useClassTicket,
  useCoin,
}) => {
  return await admin2({
    method: 'get',
    url: product(shopId),
    params: {
      start,
      limit,
      name,
      useClassTicket,
      useCoin,
    },
  })
}

//= > 取得銷售人員
export const GetSalesUnit = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: unit(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得銷售優惠
export const GetSalesDiscount = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: discount(shopId),
    params: {
      start,
      limit,
      name,
    },
  })
}

// !! 取得總數
//= > 取得銷售紀錄總數
export const GetSalesRecordCount = async ({ shopId, phone, orderNo, branchName, MemberId }) => {
  const res = await admin({
    method: 'get',
    url: `${record(shopId)}/count`,
    params: {
      phone,
      orderNo,
      branchName,
      MemberId,
    },
  })

  return res.data.count
}

//= > 取得銷售類別總數
export const GetSalesCategoryCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${category(shopId)}/count`,
    params: {
      name,
    },
  })

  return res.data.count
}

//= > 取得銷售產品總數
export const GetSalesProductCount = async ({
  shopId,
  name,
  useClassTicket,
  useCoin,
}) => {
  const res = await admin({
    method: 'get',
    url: `${product(shopId)}/count`,
    params: {
      name,
      useClassTicket,
      useCoin,
    },
  })

  return res.data.count
}

//= > 取得銷售人員總數
export const GetSalesUnitCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${unit(shopId)}/count`,
    params: {
      name,
    },
  })

  return res.data.count
}

//= > 取得銷售優惠總數
export const GetSalesDiscountCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `${discount(shopId)}/count`,
    params: {
      name,
    },
  })
}

// !! 取得指定
//= > 取得指定服務紀錄
export const FindSalesRecord = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${record(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新指定服務紀錄
export const UpdateSalesRecord = async ({
  shopId,
  id,
  note,
}) => {
  const res = await admin({
    method: 'put',
    url: `${record(shopId)}/${id}`,
    data: {
      note,
    },
  })

  return res.data
}

//= > 取得指定服務類別
export const FindSalesCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${category(shopId)}/${id}`,
  })

  return res.data
}

//= > 取得指定銷售產品
export const FindSalesProduct = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${product(shopId)}/${id}`,
  })

  return res.data
}

//= > 取得指定銷售人員
export const FindSalesUnit = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${unit(shopId)}/${id}`,
  })

  return res.data
}

//= > 取得指定銷售優惠
export const FindSalesDiscount = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${discount(shopId)}/${id}`,
  })

  return res.data
}

// !! 更新
//= > 更新銷售紀錄
export const CancelSalesRecord = async ({
  shopId,
  id,
  password,
}) => {
  const res = await admin({
    method: 'put',
    url: `${record(shopId)}/${id}/invalid`,
    data: {
      password,
    },
  })

  return res.data
}

//= > 更新銷售類別
export const UpdateSalesCategory = async ({
  shopId,
  id,
  name,
  order,
  salesProducts,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'put',
    url: `${category(shopId)}/${id}`,
    data: {
      name,
      order,
      salesProducts,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 更新銷售產品
export const UpdateSalesProduct = async ({
  shopId,
  id,
  name,
  needUnit,
  defaultPrice,
  order,
  salesCategories,
  useClassTicket,
  classTicketId,
  revenueType,
  revenueValue,
  revenueSplitCost,
  revenueSplitRate,
  useCoin,
  coinAmount,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'put',
    url: `${product(shopId)}/${id}`,
    data: {
      name,
      needUnit,
      defaultPrice,
      order,
      salesCategories,
      useClassTicket,
      classTicketId,
      revenueType,
      revenueValue,
      revenueSplitCost,
      revenueSplitRate,
      useCoin,
      coinAmount,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 更新銷售人員
export const UpdateSalesUnit = async ({
  shopId,
  id,
  name,
  profit,
  order,
  SalesGroupId,
}) => {
  const res = await admin({
    method: 'put',
    url: `${unit(shopId)}/${id}`,
    data: {
      name,
      profit,
      order,
      SalesGroupId,
    },
  })

  return res.data
}

//= > 更新銷售優惠
export const UpdateSalesDiscount = async ({
  shopId,
  id,
  name,
  type,
  cashAmount,
  percentAmount,
  mustSpendSetting,
  order,
}) => {
  return await admin2({
    method: 'put',
    url: `${discount(shopId)}/${id}`,
    data: {
      name,
      type,
      order,
      cashAmount,
      percentAmount,
      mustSpendSetting,
    },
  })
}

// !! 刪除

//= > 刪除銷售類別
export const DeleteSalesCategory = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${category(shopId)}/${id}`,
  })

  return res.data
}

//= > 刪除銷售產品
export const DeleteSalesProduct = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${product(shopId)}/${id}`,
  })

  return res.data
}

//= > 刪除銷售產品
export const DeleteSalesUnit = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${unit(shopId)}/${id}`,
  })

  return res.data
}

//= > 刪除銷售優惠
export const DeleteSalesDiscount = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${discount(shopId)}/${id}`,
  })

  return res.data
}

// !! 設置
//= > 取得店家銷售設定
export const GetSalesConfig = async ({ shopId }) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesConfig`,
  })

  return res.data
}

export const FindSalesPaymentConfig = async ({
  shopId,
  type,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/salesPaymentConfig`,
    params: {
      type,
    },
  })
}

export const UpdateSingleSalesPaymentConfig = async ({
  shopId,
  enable,
  type,
  config,
  offlinePaymentClientId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/salesPaymentConfig`,
    params: {
      type,
    },
    data: {
      type,
      enable,
      config,
      offlinePaymentClientId,
    },
  })
}

export const UpdateSalesConfig = async ({
  shopId,
  showMember,
  identityMode,
  showMemberDetail,
  enableDisplayTransaction,
  enableDisplayBalance,
  enableCashbackDiscount,
  enablePointDiscount,
  pointDiscountRate,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/salesConfig`,
    data: {
      showMember,
      identityMode,
      showMemberDetail,
      enableDisplayTransaction,
      enableDisplayBalance,
      enableCashbackDiscount,
      enablePointDiscount,
      pointDiscountRate,
    },
  })

  return res.data
}

//= >設定銷售密碼
export const SetSalesPassword = async ({
  shopId,
  password,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/salesConfig/setInvalidPassword`,
    data: {
      password,
    },
  })

  return res.data
}

//= >更新銷售付款方式
export const UpdateSalesPaymentTypes = async ({
  shopId,
  paymentTypes,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/salesConfig/updatePaymentTypes`,
    data: {
      paymentTypes,
    },
  })

  return res.data
}

//= >取得所有銷售付款方式
export const GetSalesPaymentTypes = async ({
  shopId,
}) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesConfig/allPaymentTypes`,
  })

  return res.data
}

// -- 取得銷售群組清單
export const GetSalesGroup = async ({
  shopId,
  start,
  limit,
  type,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesGroup`,
    params: {
      start,
      limit,
      type,
      name,
    },
  })

  return res.data
}

// -- 取得銷售群組清單數量
export const GetSalesGroupCount = async ({
  shopId,
  type,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/salesGroup/count`,
    params: {
      type,
      name,
    },
  })

  return res.data
}

// -- 新增銷售群組
export const PostSalesGroup = async ({
  shopId,
  type,
  name,
  order,
}) => {
  const res = await admin({
    method: 'post',
    url: `/${shopId}/salesGroup`,
    data: {
      type,
      name,
      order,
    },
  })

  return res.data
}

// -- 編輯銷售群組
export const PutSalesGroup = async ({
  shopId,
  id,
  type,
  name,
  order,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/salesGroup/${id}`,
    data: {
      type,
      name,
      order,
    },
  })

  return res.data
}

// -- 刪除銷售群組
export const DeleteSalesGroup = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `/${shopId}/salesGroup/${id}`,
  })

  return res.data
}

export const GetRecordCreationConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesRecord/creationConfig`,
  })
}

//  => 銷售報表門市銷售指標
export const FindIndicatorByBranch = async ({
  shopId,
  start,
  end,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesReport/findIndicatorByBranch`,
    params: {
      start,
      end,
    },
  })
}

// 客戶登錄銷售紀錄
export const GetSalesClientConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesClientConfig`,
  })
}

export const UpdateSalesClientConfig = async ({
  shopId,
  enable,
  productSelectMode,
  SingleProductId,
  allowClientEditPrice,
  quantityLimit,
  paymentSettings,
  discountSettings,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/salesClientConfig`,
    data: {
      enable,
      productSelectMode,
      SingleProductId,
      allowClientEditPrice,
      quantityLimit,
      paymentSettings,
      discountSettings,
    },
  })
}

// 登錄銷售連結
export const GetSalesClientLink = async ({
  shopId,
  start,
  limit,
  branchName,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesClientLink`,
    params: {
      start,
      limit,
      branchName,
    },
  })
}
export const GetSalesClientLinkCount = async ({
  shopId,
  branchName,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesClientLink/count`,
    params: {
      branchName,
    },
  })
}
export const FindSalesClientLink = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/salesClientLink/${id}`,
  })
}
export const AutoUpdateSalesClientLink = async ({
  shopId,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/salesClientLink/autoUpdate`,
    data: {},
  })
}

// 取得允許銷售人員列表
export const GetSalesUnitList = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReport(shopId)}/allowSalesUnitList`,
  })
}
// 取得銷售人員業績資料
export const GetSalesUnitPerformance = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  salesUnitIds,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReport(shopId)}/salesPerformance`,
    params: {
      createdAtStart,
      createdAtEnd,
      salesUnitIds,
    },
  })
}

// 取得銷售項目紀錄
export const GetSalesItemRecord = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  salesUnitIds,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReport(shopId)}/salesRecordItemFind`,
    params: {
      createdAtStart,
      createdAtEnd,
      salesUnitIds,
      start,
      limit,
    },
  })
}

// 取得銷售項目紀錄總數
export const GetSalesItemRecordCount = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  salesUnitIds,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReport(shopId)}/salesRecordItemCount`,
    params: {
      createdAtStart,
      createdAtEnd,
      salesUnitIds,
    },
  })
}

// 查詢銷售人員報表可綁定人員、銷售人員列表
export const GetSalesUnitBindList = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReportPermission(shopId)}/bindList`,
  })
}
// 查詢銷售人員報表檢視權限設定
export const FindSalesUnitReportConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `${unitReportPermission(shopId)}`,
  })
}

// 更新銷售人員報表檢視權限設定
export const UpdateSalesUnitReportConfig = async ({
  shopId,
  enabled,
  config,
}) => {
  return admin2({
    method: 'PUT',
    url: `${unitReportPermission(shopId)}`,
    data: {
      enabled,
      config,
    },
  })
}

export const FindSpendSettingCoupon = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/salesDiscount/findSpendSettingCoupon`,
  })
}
